<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
  <div id="page">
    <Header :add_class="['header-auto-show']" />

    <Footer logged />
    <!-- Page Title-->
    <div class="page-title page-title-fixed">
      <a
        @click="$router.go(-1)"
        class="page-title-icon rounded-s ms-3 bg-theme color-theme"
        ><i class="fas fa-chevron-left"></i
      ></a>
      <h1 class="font-18 mx-auto">{{ pageTitle }}</h1>
    </div>
    <div class="page-title-clear"></div>

    <!-- End Page Title-->
    <div class="page-content mt-4 mx-3">
      <div class="card rounded-m mb-0">
        <div class="content py-2">
          <p v-if="errors" class="color-red-dark mb-3">
            {{ errors.errorMsg }}
          </p>

          <div v-if="formSubmitResponse.success === true">
            <p>
              {{ this.formSubmitResponse.message }}
              <br/> Akan diproses dalam 1-3 hari kerja
            </p>

            <a
              href="/"
              class="
              btn btn-full btn-m
              gradient-highlight
              rounded-s
              font-13 font-600
              mt-4
            "
            >Back to Home</a>
          </div>

          <div v-else>
            <div
              class="input-style no-borders no-icon validate-field pb-3"
              :class="{ 'input-style-active': payload.talent_role }"
            >
              <select id="form1" v-model="payload.talent_role">
                <option value="default" selected>Pilih Role</option>
                <option v-for="role of roleList" :key="role.id" :value="role.name">
                  {{ role.name }}
                </option>
              </select>
              <label for="form1">Role yang di butuhkan</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em
                v-if="!payload.talent_role"
                :class="{
                  'color-red-dark font-800 req-val': errors.talent_role,
                }"
              >(required)</em>
            </div>

            <div
              class="input-style no-borders no-icon validate-field pb-3"
              :class="{ 'input-style-active': payload.talent_qualification }"
            >
              <select id="form1" v-model="payload.talent_qualification">
                <option value="default" selected>Pilih Skill</option>
                <option v-for="skill of skills" :key="skill.id" :value="skill.name">
                  {{ skill.name }}
                </option>
              </select>
              <label for="form1">Kualifikasi skill yang dibutuhkan</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em
                v-if="!payload.talent_qualification"
                :class="{
                  'color-red-dark font-800 req-val': errors.talent_qualification,
                }"
              >(required)</em>
            </div>

            <div
              class="input-style no-borders no-icon validate-field pb-3"
              :class="{ 'input-style-active': payload.estimation }"
            >
              <select id="form1" v-model="payload.estimation">
                <option value="Secepatnya">Secepatnya</option>
                <option value="1 Minggu">1 Minggu</option>
                <option value="2 Minggu">2 Minggu</option>
                <option value="1 Bulan">1 Bulan</option>
              </select>
              <label for="form1">Berapa cepat kandidat dibutuhkan</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em
                v-if="!payload.estimation"
                :class="{
                  'color-red-dark font-800 req-val': errors.estimation,
                }"
              >(required)</em>
            </div>

            <div
              class="input-style no-borders no-icon validate-field pb-5"
              :class="{ 'input-style-active': payload.talent_status }"
            >
              <select id="form1" v-model="payload.talent_status">
                <option value="Fulltime">Fulltime</option>
                <option value="Part Time">Part Time</option>
                <option value="Freelance">Freelance</option>
              </select>
              <label for="form1">Status karyawan</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em
                v-if="!payload.talent_status"
                :class="{
                  'color-red-dark font-800 req-val': errors.talent_status,
                }"
              >(required)</em>
            </div>

            <div
              class="input-style no-borders no-icon validate-field pb-3"
              :class="{ 'input-style-active': payload.pic_name }"
            >
              <input
                type="text"
                class="form-control validate-name"
                id="form1"
                v-model="payload.pic_name"
                placeholder="Nama PIC"
                required
              />
              <label for="form1">Nama PIC</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em
                v-if="!payload.pic_name"
                :class="{
                  'color-red-dark font-800 req-val': errors.pic_name,
                }"
              >(required)</em>
            </div>

            <div
              class="input-style no-borders no-icon validate-field pb-3"
              :class="{ 'input-style-active': payload.company_name }"
            >
              <input
                type="text"
                class="form-control validate-name"
                id="form1"
                v-model="payload.company_name"
                placeholder="Nama Perusahaan"
                required
              />
              <label for="form1">Nama Perusahaan</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em
                v-if="!payload.company_name"
                :class="{
                  'color-red-dark font-800 req-val': errors.company_name,
                }"
              >(required)</em>
            </div>

            <div
              class="input-style no-borders no-icon validate-field pb-3"
              :class="{ 'input-style-active': payload.phone }"
            >
              <input
                type="text"
                class="form-control validate-name"
                id="form1"
                v-model="payload.phone"
                placeholder="No Handphone"
                required
              />
              <label for="form1">No Handphone</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em
                v-if="!payload.phone"
                :class="{
                  'color-red-dark font-800 req-val': errors.phone,
                }"
              >(required)</em>
            </div>

            <div
              class="input-style no-borders no-icon validate-field pb-3"
              :class="{ 'input-style-active': payload.email }"
            >
              <input
                type="text"
                class="form-control validate-name"
                id="form1"
                v-model="payload.email"
                placeholder="Email"
                required
              />
              <label for="form1">Email</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em
                v-if="!payload.email"
                :class="{
                  'color-red-dark font-800 req-val': errors.email,
                }"
              >(required)</em>
            </div>

            <div
              class="input-style no-borders no-icon validate-field pb-5"
              :class="{ 'input-style-active': payload.total_employee }"
            >
              <select id="form1" v-model="payload.total_employee">
                <option value="default">pilih jumlah karyawan</option>
                <option value="1-10">1-10</option>
                <option value="11-25">11-25</option>
                <option value="26-50">26-50</option>
                <option value="50">50></option>
              </select>
              <label for="form1">Jumlah Karyawan</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em
                v-if="!payload.total_employee"
                :class="{
                  'color-red-dark font-800 req-val': errors.total_employee,
                }"
              >(required)</em>
            </div>

            <a
              @click.prevent="submitForm"
              href="#"
              class="
              btn btn-full btn-m
              gradient-highlight
              rounded-s
              font-13 font-600
              mt-4
            "
            >Simpan Perubahan</a>
            <router-link
              :to="{ name: 'Dashboard' }"
              href="#"
              class="
              btn btn-full btn-m
              bg-red-dark
              rounded-s
              font-13 font-600
              mt-4
            "
            >Batal</router-link>
          </div>

        </div>
      </div>
    </div>
    <!-- End Page Content -->
    <!-- modals delete -->
    <div
      id="menu-delete-job"
      class="menu menu-box-modal rounded-m"
      data-menu-height="200"
      data-menu-width="350"
      style="display: block; width: 350px; height: 200px"
    >
      <div class="menu-title">
        <i
          class="
            fa fa-question-circle
            scale-box
            float-start
            me-3
            ms-3
            fa-3x
            mt-1
            color-blue-dark
          "
        ></i>
        <p class="color-highlight">Hapus {{ pageTitle }}</p>
        <h1 class="font-20">Apakah anda yakin?</h1>
        <a href="#" @click="menuClose" class="close-menu"
          ><i class="fa fa-times-circle"></i
        ></a>
      </div>
      <div class="content mt-0">
        <p class="pe-3">{{ jobName }} akan di hapus</p>
        <div class="row mb-0">
          <div class="col-6">
            <a
              @click="menuClose"
              href="#"
              class="
                btn
                close-menu
                btn-full btn-m
                bg-red-dark
                font-600
                rounded-s
              "
              >No, batal</a
            >
          </div>
          <div class="col-6">
            <a
              @click="deleteJob"
              href="#"
              class="
                btn
                close-menu
                btn-full btn-m
                bg-green-dark
                font-600
                rounded-s
              "
              >Yes, hapus!</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- End modals delete -->
    <div
      id="emailVerifyNotif"
      class="menu menu-box-modal bg-red-dark rounded-m"
      data-menu-height="310"
      data-menu-width="350"
      style="display: block; width: 350px; height: 310px"
    >
      <h1 class="text-center mt-4">
        <i
          class="
            fa fa-3x fa-times-circle
            scale-box
            color-white
            shadow-xl
            rounded-circle
          "
        ></i>
      </h1>
      <h1 class="text-center mt-3 text-uppercase color-white font-700">
        Wooops!
      </h1>
      <p class="boxed-text-l color-white opacity-70">
        Email anda belum terverifikasi<br />
        Silahkan verifikasi email terlebih dahulu
      </p>
      <a
        @click="menuClose"
        href="#"
        class="
          close-menu
          btn btn-m btn-center-l
          button-s
          shadow-l
          rounded-s
          text-uppercase
          font-600
          bg-white
          color-black
        "
        >Keluar</a
      >
    </div>
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import EmptyState from "@/components/EmptyState";
import JobCompany from "@/components/JobCompany";
export default {
  name: "CreateJob",
  components: {
    Header,
    Footer,
    JobCompany,
    EmptyState,
  },
  data() {
    return {
      apiEndpoint: process.env.VUE_APP_ROOT_API_NODE,
      pageTitle: "Request Talent ",
      listJob: [],
      apiUrl: "/api/v1/employer/job/vacancy/",
      isLoading: true,
      jobType: "lowongan",
      isVerified: this.$store.state.account.isVerified,
      isAuthenticated: this.$store.state.isAuthenticated,
      jobId: "",
      jobName: "",
      emptyMsg: "Belum ada lowongan",
      errors: [],
      skills: [],
      roleList: [],
      formSubmitResponse: {
        success: false,
        message: ''
      },
      payload: {
        talent_role: 'default',
        talent_qualification: 'default',
        estimation: 'Secepatnya',
        talent_status: 'Fulltime',
        pic_name: '',
        company_name: '',
        email: '',
        phone: '',
        total_employee: 'default',
      }
    };
  },
  mounted() {
    init_template();
    var job_type = this.$route.params.job_type;
    if (job_type === "project") {
      this.pageTitle = "Freelance";
      this.emptyMsg = "Belum ada project";
      this.apiUrl = "/api/v1/employer/job/project/";
      this.jobType = "project";
    }
    this.getRoleList();
    this.getSkillList();
    setTimeout(() => {
      this.isLoading = false;
    }, 150);
  },
  methods: {
    menuOpen,
    menuClose,
    validateInput(dataInput) {
      // validate required field
      this.errors = {};
      for (const key in dataInput) {
        if (dataInput[key] === "") {
          this.errors[key] = "Empty Input";
          this.errors["errorMsg"] = "Beberapa form wajib di isi";
        }
      }
    },
    getRoleList() {
      axios
        .get("/job/level",{
          baseURL: this.apiEndpoint,
        })
        .then(({ data }) => {
          this.roleList = data.data
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log(JSON.stringify(error));
          }
        });
    },
    getSkillList() {
      axios
        .get("/job/skill",{
          baseURL: this.apiEndpoint,
        })
        .then(({ data }) => {
          this.skills = data.data
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log(JSON.stringify(error));
          }
        });
    },
    async submitForm() {
      this.validateInput(this.payload);

      if (!Object.keys(this.errors).length) {
        await axios.post("/job/request-talent", this.payload,{
          baseURL: this.apiEndpoint,
        })
          .then(({ data }) => {
            this.formSubmitResponse = {
              success: data.success,
              message: data.message
            }
            console.log(data, this.formSubmitResponse);
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors[property] = `${error.response.data[property]}`;
              }
              console.log(JSON.stringify(error.response.data));
            } else if (error.message) {
              this.errors["error"] = "Something went wrong. please try again";
              console.log(JSON.stringify(error));
            }
            window.scrollTo(0, 0);
          });
      }
    },
  },
};
</script>
